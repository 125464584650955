import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as actions from '../../redux/actions';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { ViewTitle } from 'components/ViewTitle';
import Loading from 'components/Loading';
import ExpensesApplyForm from '../../components/Forms/ExpensesApplyForm';
import { Card } from '@mui/material';
import { BackButton } from 'components/Button';

export default function EditExpenses({ title, subtitle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const expenses = useSelector((state) => state.expenses);
  const projects = useSelector((state) => state.project);
  const budget = useSelector((state) => state.budget);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const [date, setDate] = useState(expenses?.date);
  const [bank, setBank] = useState(expenses?.payee?.bank);
  const [projectSelect, setProjectSelect] = useState('');
  const [budgetSelect, setBudgetSelect] = useState('');

  const eachBudget = _.find(budget?.rows, { _id: budgetSelect?._id });

  useEffect(() => {
    dispatch(actions.expensesGet(id));
    dispatch(actions.projectAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    setValue('name', expenses?.name);
    setValue('price', expenses?.price);
    setValue('code', expenses?.code);
    setValue('payee?.name', expenses?.payee?.name);
    setValue('payee?.account_number', expenses?.payee?.account_number);
    setValue('project', expenses?.project?.id);
    setDate(expenses?.date);
    setBank(expenses?.payee?.bank);
    return () => {};
  }, [expenses]);

  useEffect(() => {
    dispatch(actions.budgetAll({ project_id: projectSelect?.id }));

    return () => {};
  }, [projectSelect]);

  const renderTitle = () => (
    <ViewTitle title={title} subtitle={subtitle} expenses={expenses} />
  );

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = {
        ...data,
        bank,
        date,
        budgetSelect: eachBudget,
        projectSelect,
        payee: { ...data?.payee, bank },
        note: true,
        applie: expenses?.employee,
      };
      await dispatch(actions.expensesPut(id, dataSubmit));
      await dispatch(actions.expensesAll({}));
      history.goBack();
    }
  };

  const renderForm = () => (
    <Card>
      <div className="p-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ExpensesApplyForm
            expenses={expenses}
            control={control}
            Controller={Controller}
            date={date}
            setDate={setDate}
            bank={bank}
            setBank={setBank}
            projects={projects}
            setProjectSelect={setProjectSelect}
            projectSelect={projectSelect}
            budgetSelect={budgetSelect}
            setBudgetSelect={setBudgetSelect}
            setValue={setValue}
          />
        </form>
        <div className="text-red-600"> *หากข้อมูลไม่แสดงรีเฟรช 1 ครั้ง</div>
      </div>
    </Card>
  );

  if (!expenses?.isLoading && expenses?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        {renderForm()}
      </div>
    );
  }
  return <Loading isLoading />;
}
