import EmployeeForm from './EmployeeForm';
import RoleTypeForm from './RoleTypeForm';
import UserForm from './UserForm';
import ProjectForm from './ProjectForm';
import BudgetForm from './BudgetForm';
import ExpensesApplyForm from './ExpensesApplyForm';
import DeliverForm from './DeliverForm';
import BillingForm from './BillingForm';
import CustomerForm from './CustomerForm';
import CustomerTypeForm from './CustomerTypeForm';
import DepartmentForm from './DepartmentForm';
import EditInformationForm from './EditInformationForm';

export {
  EmployeeForm,
  ExpensesApplyForm,
  RoleTypeForm,
  UserForm,
  ProjectForm,
  BudgetForm,
  DeliverForm,
  BillingForm,
  CustomerForm,
  CustomerTypeForm,
  DepartmentForm,
  EditInformationForm,
};

export default {
  EmployeeForm,
  RoleTypeForm,
  UserForm,
  ProjectForm,
  BudgetForm,
  ExpensesApplyForm,
  DeliverForm,
  BillingForm,
  CustomerForm,
  CustomerTypeForm,
  DepartmentForm,
  EditInformationForm,
};
