import React from 'react';

import {
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TablePagination,
  Chip,
  IconButton,
} from '@mui/material';
import dayjs from 'dayjs';
import { PAIDTYPE_STATUS } from 'utils/constants';
import { PAID_STATUS } from 'utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useHistory } from 'react-router';

export default function ExpensesTable({
  expenses,
  page,
  size,
  setPage,
  setSize,
  handleEdit,
  handleDelete,
  filter,
}) {
  const history = useHistory();
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const checkColor = (e) => {
    if (e?.status === 'PENDING')
      return (
        <Chip label={`${PAID_STATUS?.[e?.status]?.description}`} size="small" />
      );
    if (e?.status === 'APPROVE')
      return (
        <Chip
          label={`${PAID_STATUS?.[e?.status]?.description}`}
          color="warning"
          size="small"
        />
      );
    if (e?.status === 'SUCCESS')
      return (
        <Chip
          label={`${PAID_STATUS?.[e?.status]?.description}`}
          color="success"
          size="small"
        />
      );
  };

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small">
            {/* <colgroup>
                <col width="90%" />
                <col width="10%" />
              </colgroup> */}
            <TableHead>
              <TableRow className={'bg-gray-600 text-white'}>
                <TableCell>
                  <h1 className="  text-center  text-white "> No.</h1>
                </TableCell>
                <TableCell>
                  <h1 className="  text-center  text-white  "> Date</h1>
                </TableCell>
                <TableCell>
                  <h1 className="  text-center  text-white  ">Project</h1>
                </TableCell>

                <TableCell>
                  <h1 className="  text-center  text-white  ">Order</h1>
                </TableCell>
                <TableCell>
                  <h1 className="  text-center  text-white  ">Total</h1>
                </TableCell>
                <TableCell>
                  <h1 className="  text-center  text-white  ">Payee</h1>
                </TableCell>
                <TableCell>
                  <h1 className="  text-center   text-white ">Status</h1>
                </TableCell>
                <TableCell>
                  <h1 className=" text-center  text-white  "> </h1>
                </TableCell>
                <TableCell>
                  <h1 className=" text-center  text-white  "> </h1>
                </TableCell>
              </TableRow>
            </TableHead>

            {_.isEmpty(expenses?.rows) ? (
              <TableBody>
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={7} align="center">
                    No items
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {filter?.map((e, index) => (
                  <TableRow key={e._id} style={{ height: 60 }}>
                    <TableCell>
                      <h1 className=" text-center ">{index + 1}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center font-bold text-theme-600 ">
                        {e?.code}
                      </h1>
                      <h1 className=" text-center ">
                        {dayjs(e?.date).format('DD/MM/YYYY')}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="">{e?.project?.name || '-'}</h1>
                      <h1 className=" font-bold">
                        {e?.budget?.prefix}
                        {e?.budget?.budget_number} {e?.budget?.name || '-'}
                      </h1>
                    </TableCell>

                    <TableCell>
                      <h1 className="text-center">{e?.name}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">
                        {e?.price
                          ?.toFixed(2)
                          ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1>{e?.payee?.name || '-'}</h1>
                      <h1>{e?.payee?.bank || '-'}</h1>
                      <h1>{e?.payee?.account_number || '-'}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" py-1 ">{checkColor(e)}</h1>
                      <h1 className=" ">
                        {e?.bill_pickup ? (
                          <Chip
                            label="bill_pickup"
                            size="small"
                            color="success"
                          />
                        ) : (
                          <Chip
                            label="waiting bill"
                            size="small"
                            color="error"
                          />
                        )}
                      </h1>
                    </TableCell>
                    <TableCell align="center">
                      {e?.status === 'PENDING' && (
                        <div className="flex gap-1 justify-center  ">
                          <div>
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                history.push(
                                  `/profile/disbursement/edit/${e?._id}`,
                                )
                              }
                            >
                              <ModeEditIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => handleDelete(e._id)}
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                          {/* <Button
                            onClick={() => handleEdit(e._id)}
                            color="warning"
                            variant="contained"
                            size="small"
                          >
                            แก้ไข
                          </Button> */}
                          {/* <Button
                            onClick={() => handleDelete(e._id)}
                            color="error"
                            size="small"
                            variant="contained"
                          >
                            ลบ
                          </Button> */}
                        </div>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={expenses?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
