import React from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
export default function ExpensesHrApplyForm({
  control,
  Controller,
  date,
  setDate,
  setPaidType,
  paidType,
  projects,
  projectSelect,
  setProjectSelect,
  setValue,
  budgetSelect,
  setBudgetSelect,
  customer,
  bank,
  setBank,
}) {
  const thaiBanks = [
    'Promptpay',
    'Kasikornbank(KBank)',
    'Krungthai Bank(KTB)',
    'Siam Commercial Bank(SCB)',
    'Bangkok Bank(BBL)',
    'Bank of Ayudhya(BAY)',
    'TMBThanachart Bank(TTB)',
    'CIMB Thai Bank(CIMBT)',
    'United Overseas Bank(UOB)',
    'Kiatnakin Phatra Bank(KKP)',
    'Land and Houses Bank(LH Bank)',
    'Citibank N.A.',
  ];
  const handleChange = (event) => {
    setBank(event.target.value);
  };

  const handleCheckLevel = (data, index) => {
    const each = _.find(projects.rows, { _id: data?._id });
    setValue(`project`, each?._id);
    setProjectSelect(each);
  };

  const handleCheckCustomer = (data, index) => {
    const each = _.find(customer.rows, { _id: data?._id });
    setValue(`customer`, each?._id);
  };

  const handleCheckBudget = (data, index) => {
    const each = _.find(projectSelect?.budget, { _id: data?._id });
    setValue(`budget`, each?._id);
    setBudgetSelect(each);
  };

  return (
    <div>
      <div className="grid grid-cols-2">
        {' '}
        <div className="w-full px-1 py-1">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={`project`}
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disablePortal
                id="free-solo-demo"
                freeSolo
                options={projects?.rows || []}
                getOptionLabel={(option) =>
                  `${option.project_number} | ${option.name}`
                }
                onChange={(e, newValue) => handleCheckLevel(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...field}
                    {...params}
                    size="small"
                    label="Project"
                  />
                )}
              />
            )}
          />{' '}
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={`budget`}
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disablePortal
                id="free-solo-demo"
                freeSolo
                options={projectSelect?.budget || []}
                getOptionLabel={(option) =>
                  `${option.prefix}${option.budget_number} | ${option.name}`
                }
                onChange={(e, newValue) => handleCheckBudget(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...field}
                    {...params}
                    size="small"
                    label="Budget"
                  />
                )}
              />
            )}
          />{' '}
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'invoice_number'}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                label="invoice number"
                fullWidth
                size={'small'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'name'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Order"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'price'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="price"
                fullWidth
                type="number"
                size={'small'}
                required
              />
            )}
          />
        </div>
      </div>
      <div>
        <div className="w-full px-1 py-1">ผู้รับเงิน</div>
        <div className="lg:grid grid-cols-2 gap-2">
          <div className="w-full px-1 py-1">
            <Controller
              name={'payee.name'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้รับ"
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-1">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">ธนาคาร</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={bank}
                label="ธนาคาร"
                size="small"
                onChange={handleChange}
              >
                {thaiBanks.map((bank, index) => (
                  <MenuItem key={index} value={bank}>
                    {bank}
                  </MenuItem>
                ))}
                {/* <MenuItem value={'TRANSFER'}>โอนเงิน</MenuItem>
                    <MenuItem value={'CASH'}>เงินสด</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div className="w-full px-1 py-1">
            <Controller
              name={'payee.account_number'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="เลขบัญชี"
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </div>
  );
}
