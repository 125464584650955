import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
} from '@mui/material';
import Loading from 'components/Loading';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useHistory } from 'react-router';

export default function BusinessProfile({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const me = useSelector((state) => state.me);
  const info = useSelector((state) => state.info);
  const info_id = info?._id;

  const [checked, setChecked] = useState(info?.setting?.timestamp_image);

  useEffect(() => {
    dispatch(actions.meGet());
    dispatch(actions.getInformation());
    return () => {};
  }, []);

  // useEffect(() => {
  //   setChecked(info?.setting?.timestamp_image);
  //   return () => {};
  // }, [info]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleTimestampChange = async (event) => {
    await setChecked(event.target.checked);
    await dispatch(
      actions?.editOneInformation(info_id, {
        setting: { timestamp_image: checked },
      }),
    );
    dispatch(actions.getInformation());
  };

  const renderNofifyTimestampButton = () => {
    const lineNotifyAccessLink = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${
      info?.lineNotifyTimestamp?.clientIdTimestamp
    }&redirect_uri=${
      info?.lineNotifyTimestamp?.redirectURLTimestamp
    }&scope=notify&state=${me?.userData?.id || me?.userData?._id}`;

    if (info?.line_token_timestamp && info?.line_token_timestamp !== '') {
      return (
        <div className="flex justify-center gap-2">
          <a href={lineNotifyAccessLink}>
            <Button variant="contained" color="warning">
              เปลี่ยนบัญชี
            </Button>
          </a>{' '}
          <Button
            variant="contained"
            color="error"
            onClick={() => handleRemoveNotify()}
          >
            ยกเลิก
          </Button>
        </div>
      );
    }
    return (
      <a href={lineNotifyAccessLink}>
        <Button variant="contained" color="primary">
          รับการแจ้งเตือนผ่าน LINE
        </Button>
      </a>
    );
  };

  const renderNofifyButton = () => {
    const lineNotifyAccessLink = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${
      info?.lineNotify?.clientId
    }&redirect_uri=${info?.lineNotify?.redirectURL}&scope=notify&state=${
      me?.userData?.id || me?.userData?._id
    }`;

    if (info?.line_token_expenses && info?.line_token_expenses !== '') {
      return (
        <div className="flex justify-center gap-2">
          <a href={lineNotifyAccessLink}>
            <Button variant="contained" color="warning">
              เปลี่ยนบัญชี
            </Button>
          </a>{' '}
          <Button
            variant="contained"
            color="error"
            onClick={() => handleRemoveNotify()}
          >
            ยกเลิก
          </Button>
        </div>
      );
    }
    return (
      <a href={lineNotifyAccessLink}>
        <Button variant="contained" color="primary">
          รับการแจ้งเตือนผ่าน LINE
        </Button>
      </a>
    );
  };

  const renderCard = () => (
    <div>
      <div className="py-1">
        <Card>
          <div className="p-2">
            <div className="flex justify-between">
              <div className="font-bold">Expenses Notify</div>
              <div>{renderNofifyButton()}</div>
            </div>
          </div>
        </Card>
      </div>
      <Card>
        <div className="p-2">
          <div className="flex justify-between">
            <div className="font-bold">Timestamp Notify</div>
            <div>{renderNofifyTimestampButton()}</div>
          </div>
        </div>
      </Card>
    </div>
  );

  const renderCardInfo = () => (
    <div>
      <div>
        <Card>
          <div className="p-2">
            <div className="flex">
              <div className=" font-bold text-xl text-theme-500 ">
                Information
              </div>
              <div className=" font-bold text-xl ">
                {' '}
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    history.push(`/management/business/edit/${info_id}`)
                  }
                >
                  <ModeEditIcon fontSize="inherit" />
                </IconButton>
              </div>
            </div>
            <div>
              <b className=" text-theme-500">ชื่อบริษัท : </b> {info?.name}
            </div>
            <div>
              <b className=" text-theme-500">ชื่อภาษาอังกฤษ : </b>
              {info?.name_eng}
            </div>
            <div>
              <b className=" text-theme-500">เลขที่ผู้เสียภาษี : </b>
              {info?.tax_id}
            </div>
            <div>
              <b className=" text-theme-500">URL : </b>
              {info?.url}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );

  if (!info?.isLoading && info?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderCardInfo()}
        {renderCard()}
      </div>
    );
  }
  return <Loading isLoading />;
}
