import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';

import { Box, Button, Modal, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
// import expensesForm from 'components/Forms/expensesForm';
import ExpensesTable from '../../components/Table/ExpensesTable';
import ExpensesApplyForm from 'components/Forms/ExpensesApplyForm';
import FilterPersornalExpenses from 'components/Card/FilterPersornalExpenses';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function PersonalExpenses({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const expenses = useSelector((state) => state.expenses);
  const projects = useSelector((state) => state.project);
  const budget = useSelector((state) => state.budget);
  const me = useSelector((state) => state.me);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState(new Date());
  const [paidType, setPaidType] = useState('TRANSFER');
  const [projectSelect, setProjectSelect] = useState('');
  const [budgetSelect, setBudgetSelect] = useState('');
  const [findProject, setFindProject] = useState();
  const [search, setSearch] = useState('');
  const [bank, setBank] = useState();

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const eachBudget = _.find(budget?.rows, { _id: budgetSelect?._id });

  useEffect(() => {
    dispatch(actions.meGet());

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      actions.expensesAll({
        me: me?.userData?.id,
        search,
        findProject: findProject?._id,
      }),
    );

    return () => {};
  }, [search, findProject]);

  const getEx = () => {
    return dispatch(actions.expensesAll({ me: me?.userData?.id }));
  };
  useEffect(() => {
    getEx();
    dispatch(actions.projectAll({ status_deliver: true }));
    return () => {};
  }, [me]);

  useEffect(() => {
    dispatch(actions.budgetAll({ project_id: projectSelect?.id }));

    return () => {};
  }, [projectSelect]);

  const filter = _.filter(
    expenses?.rows,
    (e) => e?.employee?._id === me?.userData?.id,
  );

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.expensesDelete(id));
        dispatch(actions.expensesAll({}));
      } catch (error) {}
    }
  };

  const handleEdit = (id) => {
    history.push(`/expenses/edit/${id}`);
  };

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = {
        ...data,
        paidType,
        date,
        employee: me?.userData?._id,
        employee_data: me?.userData,
        budgetSelect: eachBudget,
        projectSelect,
        payee: { ...data?.payee, bank },
      };
      await dispatch(actions.expensesCreate(dataSubmit));
      await dispatch(actions.expensesAll({}));
      reset();
      handleClose();
    }
  };

  const renderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <b className="py-2">รายการเบิกจ่าย</b>
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ExpensesApplyForm
              control={control}
              Controller={Controller}
              date={date}
              setDate={setDate}
              paidType={paidType}
              setPaidType={setPaidType}
              projects={projects}
              setProjectSelect={setProjectSelect}
              projectSelect={projectSelect}
              setValue={setValue}
              budgetSelect={budgetSelect}
              setBudgetSelect={setBudgetSelect}
              bank={bank}
              setBank={setBank}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderTable = () => (
    <ExpensesTable
      expenses={expenses}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      filter={filter}
    />
  );

  const renderAddButton = () => (
    <div className="flex  justify-between ">
      <div className="w-2/3">
        <FilterPersornalExpenses
          project={projects}
          setFindProject={setFindProject}
          setSearch={setSearch}
        />
      </div>
      <div className="flex py-1">
        <Button variant="contained" size="small" onClick={handleOpen}>
          Add Expenses
        </Button>
      </div>
    </div>
  );
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderTitle()}
      {renderAddButton()}
      {renderTable()}
      {renderModal()}
    </div>
  );
}
