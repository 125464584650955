import React from 'react';

import {
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TablePagination,
  Chip,
} from '@mui/material';
import dayjs from 'dayjs';
import { PAIDTYPE_STATUS } from 'utils/constants';
import { PAID_STATUS } from 'utils/constants';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import LaunchIcon from '@mui/icons-material/Launch';

export default function ExpensesTableApplove({
  expenses,
  page,
  size,
  setPage,
  setSize,
  handleEdit,
  handleDelete,
  filter,
  handleApprove,
  handleSuccess,
  handleBillPickup,
  handleDetail,
  handleOpenPay,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const checkColor = (e) => {
    if (e?.status === 'PENDING')
      return (
        <Chip size="small" label={`${PAID_STATUS?.[e?.status]?.description}`} />
      );
    if (e?.status === 'APPROVE')
      return (
        <Chip
          label={`${PAID_STATUS?.[e?.status]?.description}`}
          color="warning"
          size="small"
        />
      );
    if (e?.status === 'SUCCESS')
      return (
        <Chip
          label={`${PAID_STATUS?.[e?.status]?.description}`}
          color="success"
          size="small"
        />
      );
  };

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small">
            {/* <colgroup>
                <col width="90%" />
                <col width="10%" />
              </colgroup> */}
            <TableHead>
              <TableRow className={'bg-gray-600'}>
                <TableCell>
                  <h1 className="text-white text-center "> No.</h1>
                </TableCell>
                <TableCell>
                  <h1 className="text-white text-center "> Id/Date</h1>
                </TableCell>
                <TableCell>
                  <h1 className="text-white text-center ">Project</h1>
                </TableCell>
                <TableCell>
                  <h1 className="text-white text-center ">Invoice/customer</h1>
                </TableCell>
                <TableCell>
                  <h1 className="text-white text-center ">Order</h1>
                </TableCell>
                <TableCell>
                  <h1 className="text-white text-center ">Total</h1>
                </TableCell>
                <TableCell>
                  <h1 className="  text-center  text-white  ">Payee</h1>
                </TableCell>
                <TableCell>
                  <h1 className="text-white text-center ">Applicant</h1>
                </TableCell>
                <TableCell>
                  <h1 className="text-white text-center ">Status</h1>
                </TableCell>
                <TableCell>
                  <h1 className="text-whitetext-center "> </h1>
                </TableCell>
              </TableRow>
            </TableHead>

            {_.isEmpty(filter) ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No items
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {filter?.map((e, index) => (
                  <TableRow key={e._id}>
                    <TableCell>
                      <h1 className=" text-center ">{index + 1}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center font-bold text-theme-600 ">
                        {e?.code}
                      </h1>
                      <h1 className=" text-center ">
                        {dayjs(e?.date).format('DD/MM/YYYY')}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="">{e?.project?.name || '-'}</h1>

                      <h1 className="font-bold">
                        {e?.budget?.prefix}
                        {e?.budget?.budget_number} {e?.budget?.name || '-'}
                      </h1>
                    </TableCell>

                    <TableCell>
                      <h1 className="">{e?.invoice_number || '-'}</h1>
                      <h1 className="">{e?.customer?.name || '-'}</h1>
                    </TableCell>
                    <TableCell>
                      <div className="flex">
                        <h1 className="">{e?.name}</h1>
                        <IconButton
                          size="small"
                          onClick={() => handleDetail(e._id)}
                        >
                          <LaunchIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">
                        {e?.price
                          ?.toFixed(2)
                          ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1>
                        <b>Name: </b>
                        {e?.payee?.name || '-'}
                      </h1>
                      <h1>
                        <b>Bank: </b>
                        {e?.payee?.bank || '-'}
                      </h1>
                      <h1>
                        <b>Account: </b>
                        {e?.payee?.account_number || '-'}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="">
                        {e?.employee?.firstname} {e?.employee?.lastname}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="py-1">{checkColor(e)}</h1>
                      <h1 className=" ">
                        {e?.bill_image ? (
                          <Chip
                            label="bill_pickup"
                            size="small"
                            color="success"
                          />
                        ) : (
                          <Chip
                            label="waiting bill"
                            size="small"
                            color="error"
                          />
                        )}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-1 ">
                        {e?.status === 'APPROVE' ||
                          (e?.status === 'PENDING' && (
                            <Button
                              variant="contained"
                              size="small"
                              disabled={
                                e?.status === 'APPROVE' ||
                                e?.status === 'SUCCESS'
                              }
                              onClick={() => handleApprove(e?.id)}
                            >
                              Approve
                            </Button>
                          ))}
                        {e?.status === 'APPROVE' && (
                          <Button
                            variant="contained"
                            size="small"
                            color="success"
                            disabled={e?.status != 'APPROVE'}
                            onClick={() => handleOpenPay(e)}
                          >
                            add payment
                          </Button>
                        )}
                        {e?.status === 'SUCCESS' && ( //โค้ดที่โง่ที่สุด by naayti9
                          <Button
                            variant="contained"
                            size="small"
                            color="success"
                            disabled
                            onClick={() => handleSuccess(e?.id)}
                          >
                            add payment
                          </Button>
                        )}
                        {/* {e?.status === 'SUCCESS' && (
                          <Button
                            variant="contained"
                            size="small"
                            color="success"
                            disabled={e?.bill_pickup}
                            onClick={() => handleBillPickup(e?.id)}
                          >
                            bill pickup
                          </Button>
                        )} */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 30, 50, 70, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={expenses?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
