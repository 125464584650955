import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

export default function HrDashboardCard({ timestamp }) {
  return (
    <div>
      {_.map(timestamp?.rows, (e, index) => (
        <div className="py-1">
          <Card>
            <div className="p-2">
              <div>
                <h1 className="text-lg font-bold py-1 ">
                  <b className="text-theme-600">Project : </b>{' '}
                  {e?.projectDetails?.project_number} |{' '}
                  {e?.projectDetails?.name}
                </h1>

                <div>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow className={'bg-gray-200 text-white'}>
                        <TableCell>
                          {' '}
                          <b>No.</b>{' '}
                        </TableCell>
                        <TableCell>
                          <b>Name</b>
                        </TableCell>
                        <TableCell>
                          <b>In</b>
                        </TableCell>
                        <TableCell>
                          <b>Cost</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_?.map(e?.timesTampData, (data, index) => (
                        <TableRow>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {data?.employeeDetails?.firstname}{' '}
                            {data?.employeeDetails?.lastname}
                          </TableCell>
                          <TableCell>
                            {dayjs(data?.checkIn).format('HH:mm')}
                          </TableCell>
                          <TableCell>
                            {data?.salary?.day
                              ?.toFixed(2)
                              ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={2} align="right">
                          <b>Total</b>
                          <b> {e?.timesTampData?.length} Person</b>{' '}
                        </TableCell>

                        <TableCell align="right">
                          <b> Estimate</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {_?.sumBy(e?.timesTampData, (t) => t?.salary?.day)
                              ?.toFixed(2)
                              ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            /day{' '}
                            {_?.sumBy(e?.timesTampData, (t) => t?.salary?.hr) *
                              1.5
                                ?.toFixed(2)
                                ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
                            /hr OT
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
}
