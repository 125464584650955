import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
export default function EditInformationForm({
  errors,
  info,
  Controller,
  control,
}) {
  return (
    <div>
      <div className="grid grid-cols-2">
        <div className="w-full px-1 py-2">
          <Controller
            name={'name'}
            control={control}
            defaultValue={info ? info.name : ''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                required
                fullWidth
                size={'small'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'name_eng'}
            control={control}
            defaultValue={info ? info.name_eng : ''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Name English "
                required
                fullWidth
                size={'small'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'tax_id'}
            control={control}
            defaultValue={info ? info.tax_id : ''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Tax ID"
                required
                fullWidth
                size={'small'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'url'}
            control={control}
            defaultValue={info ? info.url : ''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="URL"
                required
                fullWidth
                size={'small'}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
