import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const timeStampMontlyReport = (timestamp, days, month, year, dateStart) => {
  // console.log('timestamp pdf', days);

  const tableBody = [
    [
      { text: 'No.', style: 'tableHeader', fillColor: '#d2d4d2' },
      { text: 'Name', style: 'tableHeader', fillColor: '#d2d4d2' },
      { text: 'Type', style: 'tableHeader', fillColor: '#d2d4d2' },
      ...days.map((day) => {
        const date = dayjs(`${year}-${month + 1}-${day}`);
        const isWeekend = date.day() === 0 || date.day() === 6;
        if (isWeekend) {
          return {
            text: day.toString(),
            style: 'tableHeader',
            fillColor: '#ff0017',
          };
        }
        return {
          text: day.toString(),
          style: 'tableHeader',
          fillColor: '#d2d4d2',
        };
      }),
      { text: 'Working', style: 'tableHeader', fillColor: '#d2d4d2' },
      { text: 'Day Rate', style: 'tableHeader', fillColor: '#d2d4d2' },
      { text: 'Amount', style: 'tableHeader', fillColor: '#d2d4d2' },
    ],
  ];

  timestamp?.rows?.forEach((employee, idx) => {
    tableBody.push([
      { text: idx + 1, rowSpan: 2 },
      {
        text: `${employee?.employeeDetails?.firstname} ${employee?.employeeDetails?.lastname}`,
        rowSpan: 2,
      },
      'normal',
      ...days.map((day) => {
        const foundData = employee.timesTampData?.find(
          (e) =>
            dayjs(e?.checkIn).format('D/M/YY') ===
            `${day}/${month + 1}/${year % 100}`,
        );
        return `${foundData?.normal_time || 0}`;
      }),
      { text: `${employee?.totalWork}` },
      {
        text: employee?.employeeDetails?.salary?.day?.toFixed(2) || '-',
        rowSpan: 2,
        style: 'tableBody',
      },
      {
        text: employee?.totalLabor?.toFixed(2) || '-',
        style: 'tableBody',
        rowSpan: 2,
      },
    ]);
    tableBody.push([
      {},
      {},
      'ot',
      ...days.map((day) => {
        const foundData = employee.timesTampData?.find(
          (e) =>
            dayjs(e?.checkIn).format('D/M/YY') ===
            `${day}/${month + 1}/${year % 100}`,
        );
        return `${foundData?.ot_time || ' '}`;
      }),
      {
        text: employee?.totalWork_OT || '-',
      },
      {},
      {},
    ]);
  });

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'LandScape',
    pageMargins: [20, 80, 20, 60],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '9',
    },
    info: {
      title: 'report ',
    },
    header: [
      {
        margin: [20, 12],
        table: {
          alignment: '',

          widths: ['100%'],
          body: [
            [
              {
                text: 'Employee Time Sheet ',
                style: 'header',
                border: [false, false, false, false],
                alignment: 'center',
              },
            ],
            [
              {
                text: `Date : ${dayjs(dateStart).format('MMMM')} ${year}`,
                style: 'header',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    content: [
      {
        table: {
          headerRows: 1,
          widths: [
            'auto',
            '*',
            'auto',
            ...days.map(() => 'auto'),
            'auto',
            'auto',
            '*',
          ],
          body: tableBody,
        },
      },
    ],
    footer: function (currentPage, pageCount) {
      return {
        margin: [20, 0],
        table: {
          widths: ['*', 'auto'],
          body: [
            [
              '',
              {
                text: `page ${currentPage} / ${pageCount}`,
                alignment: 'right',
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: 'noBorders',
      };
    },
    styles: {
      header: { fontSize: 14, bold: true },
      tableHeader: { bold: true, fillColor: '#CCCCCC', alignment: 'center' },
      tableBody: { alignment: 'right' },
    },
  };

  pdfMake.createPdf(docDefinition).open();
};
export default timeStampMontlyReport;
