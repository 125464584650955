import React, { useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(weekday);
dayjs.extend(localeData);

const Monthlyreport = ({
  timestamp,
  month,
  setMonth,
  year,
  daysInMonth,
  days,
  handleValueChange,
  selectedOption,
}) => {
  return (
    <div className="">
      <div className="w-full">
        <div className="flex justify-end py-1">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedOption}
            label="length"
            onChange={handleValueChange}
            size="small"
          >
            <MenuItem value={'Month'}>Month</MenuItem>
            <MenuItem value={'15'}>1-15</MenuItem>
            <MenuItem value={'31'}>15-31</MenuItem>
          </Select>
        </div>
      </div>
      <div className="overflow-auto">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-400  ">
              <th className="border border-gray-300 p-2 ">No.</th>
              <th className="border border-gray-300 p-2 w-[600px]">Name</th>
              <th className="border border-gray-300 p-2">Type</th>
              {days?.map((day) => {
                const date = dayjs(`${year}-${month + 1}-${day}`);
                const isWeekend = date.day() === 0 || date.day() === 6;
                return (
                  <th
                    key={day}
                    className={`border border-gray-300 p-2 ${
                      isWeekend ? 'bg-red-400' : ''
                    }`}
                  >
                    {day}
                  </th>
                );
              })}
              <th className="border border-gray-300 p-2">Working</th>
              <th className="border border-gray-300 p-2">Day Rate</th>
              <th className="border border-gray-300 p-2">Amount</th>
            </tr>
          </thead>
          <tbody>
            {timestamp?.rows?.map((employee, idx) => (
              <tr
                key={idx}
                className="text-center  hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <td className="border border-gray-300 p-2">{idx + 1}</td>
                <td className="border border-gray-300 p-2">
                  {employee?.employeeDetails?.firstname}{' '}
                  {employee?.employeeDetails?.lastname}
                </td>
                <td className="border border-gray-300 p-2">
                  <div className="border-b border-gray-300 p-1">normal</div>
                  <div className="p-1">ot</div>
                </td>
                {days?.map((day) => (
                  <td
                    key={day}
                    className="border border-gray-300 p-2 text-center"
                  >
                    <div className="p-1 border-b border-gray-400 pb-1 mb-1">
                      {employee.timesTampData?.find(
                        (e) =>
                          dayjs(e?.checkIn).format('D/M/YY') ===
                          `${day}/${month + 1}/${year % 100}`,
                      )?.normal_time || '-'}
                    </div>
                    <div className="p-1">
                      {employee.timesTampData?.find(
                        (e) =>
                          dayjs(e?.checkIn).format('D/M/YY') ===
                          `${day}/${month + 1}/${year % 100}`,
                      )?.ot_time || '-'}
                    </div>
                  </td>
                ))}
                <td className="border border-gray-300 p-2">
                  <div className="p-1 border-b border-gray-400 pb-1 mb-1">
                    {employee?.totalWork}
                  </div>
                  <div className="p-1">{employee?.totalWork_OT}</div>
                </td>
                <td className="border border-gray-300 p-2">
                  {employee?.employeeDetails?.salary?.day
                    ?.toFixed(2)
                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </td>
                <td className="border border-gray-300 p-2">
                  {employee?.totalLabor
                    ?.toFixed(2)
                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Monthlyreport;
