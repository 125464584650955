import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

import _ from 'lodash';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (rows) => {
  const productList = _.map(rows, (row, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: `${dayjs(row?.date).format('DD/MM/YYYY')}`,
      alignment: 'center',
    },
    {
      text: [
        { text: row?.project?.name },
        { text: '\n' },
        { text: 'budget : ' + row?.budget?.name, bold: true },
      ],
    },
    { text: `${row?.name || ''}` },
    {
      text: `${
        row?.price?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || ''
      }`,
      alignment: 'center',
    },

    {
      text: [
        { text: row?.payee?.account_number },
        { text: '\n' },
        { text: row?.payee?.name },
        { text: '\n' },
        { text: row?.payee?.bank },
      ],
    },
    {
      text: `${row?.employee?.firstname} ${row?.employee?.lastname}`,
      alignment: 'center',
    },
    {
      text: [{ text: ` ` }],
      alignment: 'center',
    },
  ]);

  return productList;
};

const expensesReport = async (row, info) => {
  // eslint-disable-next-line global-require

  const genItemBody = genBody(row?.rows);
  const total = _.sumBy(row?.rows, 'price');
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'LandScape',
    pageMargins: [20, 80, 20, 60],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '12',
    },
    info: {
      title: 'report ',
    },
    header: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text: 'Expenses Report',
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `${info?.name}`,
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    content: [
      {
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['5%', '10%', '28%', '18%', 'auto', 'auto', 'auto', '*'],
          body: [
            [
              {
                text: 'ลำดับ.',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'วันที่',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'โครงการ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จ่ายให้',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ผู้ขอ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'หมายเหตุ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
            [
              {
                text: 'รวมเงินทั้งหมด',
                fillColor: '#d2d4d2',
                alignment: 'center',
                colSpan: 5,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {},

              {
                text: total?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                alignment: 'right',
                colSpan: 3,
                border: [true, true, true, true],
              },
              {},
              {},
            ],
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: function (currentPage, pageCount) {
      return {
        margin: [20, 0],
        table: {
          widths: ['*', 'auto'],
          body: [
            [
              '',
              {
                text: `page ${currentPage} / ${pageCount}`,
                alignment: 'right',
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
          ],
        },
        layout: 'noBorders',
      };
    },

    styles: {
      headerPage: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      header1: {
        fontSize: 11,
        bold: true,
        alignment: 'center',
      },
      header2: {
        alignment: 'right',
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default expensesReport;
